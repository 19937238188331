@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;

@layer base {
  [type="text"],
  [type="email"],
  [type="url"],
  [type="password"],
  [type="number"],
  [type="date"],
  [type="datetime-local"],
  [type="month"],
  [type="search"],
  [type="tel"],
  [type="time"],
  [type="week"],
  [multiple],
  textarea,
  select {
    @apply bg-white dark:bg-neutral-900 border-neutral-200 dark:border-neutral-700 rounded-full focus:ring-indigo-500 
    focus:border-indigo-500 block w-full sm:text-sm text-neutral-900 dark:text-neutral-200;
  }
}
.hardText-ltr{
  direction: ltr !important;
  display: flex;
  flex-direction: column;
}
.gallery-first-img{
  min-height: 10em;
  width: 100%;
}
@media only screen and (max-width: 768px) {
  .floating-whatsapp-button{
    bottom: 4.5rem !important;
    right: 1rem !important;
    /* display: none; */
  }
  .float-wa-icon {
    display: none;
  }

}

.menu-attraction-dropdown{
  max-width: 900px !important;
  right: 0;
}
.gallery-images{
  height: 570px; 
  width: 100%; 
}


@media only screen and (max-width: 800px) {
  .gallery-images{
    height: 250px;
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .gallery-images{
    height: 185px;
    width: 100%;
  }
}

.swiper-container {
  padding-bottom: 35px;
}
.swiper-container-horizontal > .swiper-pagination {
    position: absolute;
    bottom: 0;
}